import { useCallback } from "react";
import { clamp01 } from "./helpers";
import "./Controls.css";

interface ControlsProps {
  time: number;
  length: number;
  isPlaying: boolean;
  play: () => void;
  pause: () => void;
  seek: (time: number) => void;
}

export const Controls = ({
  time,
  length,
  isPlaying,
  play,
  pause,
  seek,
}: ControlsProps) => {
  const readyToInteract = time > 500;

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const clickTarget = e.currentTarget;
      const rect = clickTarget.getBoundingClientRect();
      const relativeX = clamp01((e.clientX - rect.left) / rect.width);
      if (!readyToInteract) {
        play();
      } else {
        seek(relativeX * length);
      }
    },
    [length, play, seek, readyToInteract]
  );

  const onMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const clickTarget = e.currentTarget;
      const rect = clickTarget.getBoundingClientRect();

      const onMouseMove = (e: MouseEvent) => {
        const relativeX = clamp01((e.clientX - rect.left) / rect.width);
        seek(relativeX * length);
      };

      const onMouseUp = (e: MouseEvent) => {
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("mouseup", onMouseUp);
      };

      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mouseup", onMouseUp);
      return () => {
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("mouseup", onMouseUp);
      };
    },
    [length, seek]
  );

  const onTouchMove = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      const clickTarget = e.currentTarget;
      const rect = clickTarget.getBoundingClientRect();
      const relativeX = clamp01((e.touches[0].pageX - rect.left) / rect.width);
      if (!readyToInteract) {
        play();
      } else {
        seek(relativeX * length);
      }
    },
    [length, play, seek, readyToInteract]
  );

  return (
    <div className="Controls">
      <div id="playPauseBtn" onClick={() => (isPlaying ? pause() : play())}>
        {isPlaying ? (
          <svg
            className="pause"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M11 22h-4v-20h4v20zm6-20h-4v20h4v-20z" />
          </svg>
        ) : (
          <svg
            className="play"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M3 22v-20l18 10-18 10z" />
          </svg>
        )}
      </div>
      <div
        id="range"
        onClick={onClick}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchMove}
        onTouchMove={onTouchMove}
      >
        {/* {(time / 1000).toFixed(3)} */}
        <div className="track">
          <div
            className="rangeProgress"
            style={{
              width: 100 * (time / length) + "%",
              opacity: clamp01(time / 1000),
            }}
          ></div>
          <div
            id="rangeThumb"
            style={{
              left: 100 * (time / length) + "%",
              opacity: clamp01(time / 1000),
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};
