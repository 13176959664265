import { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";

interface PortalProps {
  container: HTMLElement;
  children: ReactNode;
}

export const Portal = ({ container, children }: PortalProps) => {
  const [innerHtmlEmptied, setInnerHtmlEmptied] = useState(false);
  useEffect(() => {
    if (!innerHtmlEmptied) {
      container.innerHTML = "";
      setInnerHtmlEmptied(true);
    }
  }, [container, innerHtmlEmptied]);
  if (!innerHtmlEmptied) return null;
  return createPortal(children, container);
};
