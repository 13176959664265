import "./Footer.css";

export const Footer = () => {
  return (
    <footer>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://maya-yianni.bandcamp.com"
        id="buy-btn"
      >
        Buy
      </a>
      <div className="spacer"></div>
      <div className="social">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://maya-yianni.bandcamp.com"
          title="Bandcamp"
        >
          <svg
            version="1.1"
            id="svg2"
            width="24"
            height="24"
            viewBox="0 0 682.66669 682.66669"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs id="defs6">
              <clipPath clipPathUnits="userSpaceOnUse" id="clipPath20">
                <path
                  d="m 3208.57,2725.96 c -183.49,0 -277.25,-144.42 -277.25,-362 0,-205.66 101.38,-360.04 277.25,-360.04 198.89,0 273.45,182.02 273.45,360.04 -0.14,185.9 -93.84,362 -273.51,362 M 2721,3265.92 h 217.97 v -522.15 h 3.85 c 59.28,98.87 183.55,160.21 294.46,160.21 311.63,0 462.74,-245.35 462.74,-544.05 0,-274.84 -133.84,-533.97 -426.43,-533.97 -133.85,0 -277.25,33.54 -342.29,168.09 h -3.78 v -140.51 h -206.53 v 1412.35 l 0.01,0.03"
                  clipRule="evenodd"
                  id="path18"
                />
              </clipPath>
              <clipPath clipPathUnits="userSpaceOnUse" id="clipPath26">
                <path d="M 0,0 H 5120 V 5120 H 0 Z" id="path24" />
              </clipPath>
              <clipPath clipPathUnits="userSpaceOnUse" id="clipPath36">
                <path
                  d="m 4503.89,2534.02 c -17.22,124.62 -105.15,191.92 -225.61,191.92 -112.87,0 -271.54,-61.35 -271.54,-371.91 0,-170.13 72.64,-350.15 261.97,-350.15 126.18,0 214.11,87.14 235.18,233.51 h 217.97 c -40.16,-265.06 -198.84,-411.43 -453.15,-411.43 -309.78,0 -479.95,227.42 -479.95,528.07 0,308.56 162.53,549.89 487.6,549.89 229.44,0 424.54,-118.68 445.5,-369.91 h -217.61 l -0.36,0.01"
                  clipRule="evenodd"
                  id="path34"
                />
              </clipPath>
              <clipPath clipPathUnits="userSpaceOnUse" id="clipPath42">
                <path d="M 0,0 H 5120 V 5120 H 0 Z" id="path40" />
              </clipPath>
              <clipPath clipPathUnits="userSpaceOnUse" id="clipPath52">
                <path
                  d="M 1958.95,1856.62 H 324.078 l 763.462,1409.3 h 1634.88 l -763.47,-1409.3"
                  clipRule="evenodd"
                  id="path50"
                />
              </clipPath>
              <clipPath clipPathUnits="userSpaceOnUse" id="clipPath58">
                <path d="M 0,0 H 5120 V 5120 H 0 Z" id="path56" />
              </clipPath>
            </defs>
            <g id="g8" transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)">
              <g id="g10" transform="scale(0.1)">
                <path
                  d="M -50,-50 H 5170 V 5170 H -50 Z"
                  style={{
                    fillOpacity: 1,
                    fillRule: "nonzero",
                    stroke: "none",
                  }}
                  id="path12"
                />
                <g id="g14">
                  <g id="g16" clipPath="url(#clipPath20)">
                    <g id="g22" clipPath="url(#clipPath26)">
                      <path
                        d="M 2670.99,1775.96 H 3750.02 V 3315.92 H 2670.99 Z"
                        style={{
                          fill: "#000000",
                          fillOpacity: 1,
                          fillRule: "nonzero",
                          stroke: "none",
                        }}
                        id="path28"
                      />
                    </g>
                  </g>
                </g>
                <g id="g30">
                  <g id="g32" clipPath="url(#clipPath36)">
                    <g id="g38" clipPath="url(#clipPath42)">
                      <path
                        d="m 3738.76,1775.96 h 1033.1 v 1177.96 h -1033.1 z"
                        style={{
                          fill: "#000000",
                          fillOpacity: 1,
                          fillRule: "nonzero",
                          stroke: "none",
                        }}
                        id="path44"
                      />
                    </g>
                  </g>
                </g>
                <g id="g46">
                  <g id="g48" clipPath="url(#clipPath52)">
                    <g id="g54" clipPath="url(#clipPath58)">
                      <path
                        d="m 274.078,1806.62 h 2498.34 v 1509.3 H 274.078 Z"
                        style={{
                          fill: "#000000",
                          fillOpacity: 1,
                          fillRule: "nonzero",
                          stroke: "none",
                        }}
                        id="path60"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </a>

        <a
          target="_blank"
          rel="noreferrer"
          href="https://music.apple.com/artist/maya-yianni/1061850608"
          title="Apple Music"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M22 17.607c-.786 2.28-3.139 6.317-5.563 6.361-1.608.031-2.125-.953-3.963-.953-1.837 0-2.412.923-3.932.983-2.572.099-6.542-5.827-6.542-10.995 0-4.747 3.308-7.1 6.198-7.143 1.55-.028 3.014 1.045 3.959 1.045.949 0 2.727-1.29 4.596-1.101.782.033 2.979.315 4.389 2.377-3.741 2.442-3.158 7.549.858 9.426zm-5.222-17.607c-2.826.114-5.132 3.079-4.81 5.531 2.612.203 5.118-2.725 4.81-5.531z" />
          </svg>
        </a>

        <a
          target="_blank"
          rel="noreferrer"
          href="https://open.spotify.com/artist/5XYZGYJWpfKUrqqElyF6XW"
          title="Spotify"
        >
          <svg
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
          >
            <path d="M19.098 10.638c-3.868-2.297-10.248-2.508-13.941-1.387-.593.18-1.22-.155-1.399-.748-.18-.593.154-1.22.748-1.4 4.239-1.287 11.285-1.038 15.738 1.605.533.317.708 1.005.392 1.538-.316.533-1.005.709-1.538.392zm-.126 3.403c-.272.44-.847.578-1.287.308-3.225-1.982-8.142-2.557-11.958-1.399-.494.15-1.017-.129-1.167-.623-.149-.495.13-1.016.624-1.167 4.358-1.322 9.776-.682 13.48 1.595.44.27.578.847.308 1.286zm-1.469 3.267c-.215.354-.676.465-1.028.249-2.818-1.722-6.365-2.111-10.542-1.157-.402.092-.803-.16-.895-.562-.092-.403.159-.804.562-.896 4.571-1.045 8.492-.595 11.655 1.338.353.215.464.676.248 1.028zm-5.503-17.308c-6.627 0-12 5.373-12 12 0 6.628 5.373 12 12 12 6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12z" />
          </svg>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/mayayiannimusic/"
          title="Instagram"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
          </svg>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/maya.yianni"
          title="Facebook"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="24"
            viewBox="3 0 18 24"
          >
            <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
          </svg>
        </a>

        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.youtube.com/user/mayayianni"
          title="YouTube"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
          </svg>
        </a>
      </div>
    </footer>
  );
};
