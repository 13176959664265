import { MyCanvas } from "./Canvas";
import { Controls } from "./Controls";
import { Footer } from "./components/footer/Footer";
import "./App.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { Loading } from "./components/loading/Loading";

function App() {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [time, setTime] = useState(0);
  const length = 326844;

  const play = useCallback(() => {
    audioRef.current?.play();
  }, []);

  const pause = useCallback(() => {
    audioRef.current?.pause();
  }, []);

  const seek = useCallback((time: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime = time / 1000;
      audioRef.current.play();
    }
  }, []);

  const onEnded = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  }, []);

  const onPlay = useCallback(() => {
    setIsPlaying(true);
  }, []);

  const onPause = useCallback(() => {
    setIsPlaying(false);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      if (audioRef.current) {
        setTime(audioRef.current.currentTime * 1000);
      }
    }, 100);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === " ") {
        if (audioRef.current?.paused) {
          play();
        } else {
          pause();
        }
      }
    };
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  });

  return (
    <div className="App">
      <Loading />
      <MyCanvas />
      <audio
        ref={audioRef}
        src="/rd.mp3"
        onPlay={onPlay}
        onPause={onPause}
        onEnded={onEnded}
      />
      <div className="UIElements">
        <header>
          <h1>
            Maya
            <br />
            Yianni
          </h1>
          <h2>Red Drapes</h2>
        </header>
        {!isPlaying && <div className="StartButton" onClick={play}></div>}
        <Controls
          time={time}
          length={length}
          isPlaying={isPlaying}
          play={play}
          pause={pause}
          seek={seek}
        />
        <Footer />
      </div>
    </div>
  );
}

export default App;
