import {
  EffectComposer,
  DepthOfField,
  Bloom,
  Noise,
  Vignette,
} from "@react-three/postprocessing";
import {
  memo,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { Flowers } from "./Flowers";
import { AmbientLight, Group, Mesh, PointLight, SpotLight } from "three";
import { createTimeline, TimelineTarget } from "./timeline";
import { AnimeTimelineInstance } from "animejs";
import { Stats } from "@react-three/drei";
// import { GUI } from "dat.gui";
import { SamEffect, SamPass } from "./postprocessing/SamPass";
import { BlendFunction, BloomEffect, DepthOfFieldEffect } from "postprocessing";
import { FadeEffect, FadePass } from "./postprocessing/FadePass";

const PERFORMANCE_CHECK_WAIT = 1000;

export type SceneQuality = "hi" | "med" | "lo";

interface SceneProps {
  quality: SceneQuality;
  debugMode: boolean;
  onReportFPS: (fps: number) => void;
}

export const SceneWithLoader = (props: SceneProps) => {
  return (
    <Suspense>
      <Scene {...props} />
    </Suspense>
  );
};

export const Scene = memo(({ quality, debugMode, onReportFPS }: SceneProps) => {
  const [focusDistance, setFocusDistance] = useState(0.22887249736564805);

  const audioRef = useRef<HTMLAudioElement>();
  const ambientLightRef = useRef<AmbientLight>(null);
  const directionalLightRef = useRef<SpotLight>(null);
  const frontLightRef = useRef<PointLight>(null);
  const backLightRef = useRef<PointLight>(null);
  const groundRef = useRef<Mesh>(null);
  const samPassRef = useRef<SamEffect>();
  const fadePassRef = useRef<FadeEffect>();
  const bloomRef = useRef<BloomEffect>();
  const dofRef = useRef<DepthOfFieldEffect>(null);
  const lastTime = useRef(performance.now());
  const lastResetTime = useRef(performance.now());

  const flowersRef = useRef<Group>(null);
  const animTarget = useRef<TimelineTarget>({
    syncWithAudio: true,
    afterImage: 0.95,
    sOffset: 0,
    sNoise: 0,
    sStrobe: 0,
    sWobble: 0,
    rotate: 0,
    dof: 0.2,
    fade: 0,
    rays: 0,
    bloomStrength: 0.1,
    cameraY: 1.5,
    globalIntensity: 1,
    ambientIntensity: 0.08, // 5 for lighter parts, 2 normally
    frontLightIntensity: 10, // Scale from 1 to 100 (10 default)
    backLightIntensity: 10, // Scale from 1 to 100 (10 default)
  });

  const timeline = useRef<AnimeTimelineInstance>();

  useEffect(() => {
    const el = document.getElementsByTagName("audio")?.[0];
    if (el) {
      audioRef.current = el;
    }
  }, []);

  useEffect(() => {
    setFocusDistance(0.189);
  }, []);

  const onMouseMove = useCallback((e: MouseEvent) => {
    if (frontLightRef.current && backLightRef.current) {
      const mouseX = e.pageX / window.innerWidth - 0.5;
      const lightXOffset = mouseX * 4;

      frontLightRef.current.position.set(
        Math.cos(0.5 * Math.PI - Math.PI * lightXOffset) * 70,
        Math.sin(0.5 * Math.PI - Math.PI * lightXOffset) * 70,
        20
      );

      backLightRef.current.position.set(
        Math.cos(0.5 * Math.PI - Math.PI * lightXOffset) * -70,
        Math.sin(0.5 * Math.PI - Math.PI * lightXOffset) * -70,
        20 + Math.sin(0.5 * Math.PI - Math.PI * lightXOffset) * -30
      );
    }
  }, []);

  const onTouchMove = useCallback((e: TouchEvent) => {
    if (frontLightRef.current && backLightRef.current) {
      const mouseX = e.touches[0].screenX / window.innerWidth - 0.5;
      const lightXOffset = mouseX * 4;

      frontLightRef.current.position.set(
        Math.cos(0.5 * Math.PI - Math.PI * lightXOffset) * 70,
        Math.sin(0.5 * Math.PI - Math.PI * lightXOffset) * 70,
        20
      );

      backLightRef.current.position.set(
        Math.cos(0.5 * Math.PI - Math.PI * lightXOffset) * -70,
        Math.sin(0.5 * Math.PI - Math.PI * lightXOffset) * -70,
        20 + Math.sin(0.5 * Math.PI - Math.PI * lightXOffset) * -30
      );
    }
  }, []);

  useEffect(() => {
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("touchmove", onTouchMove);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("touchmove", onTouchMove);
    };
  });

  const { camera } = useThree();
  // const setPixelRatio = useThree((state) => state.setDpr);

  useEffect(() => {
    const handleResize = () => {
      const viewWidth = window.innerWidth;
      const viewHeight = window.innerHeight;

      camera.layers.enable(1); // Enable layer 1 for the camera

      if (!camera || !("aspect" in camera)) {
        return;
      }

      // camera.aspect = viewWidth / viewHeight;

      // Adjust the field of view to keep the object visible
      const aspectRatio = viewWidth / viewHeight;
      if (aspectRatio < 1) {
        // Increase FOV for taller viewports
        camera.fov = Math.min(90, 65 / aspectRatio); // Base FOV is 45
      } else {
        // Reset to the default FOV for wider viewports
        camera.fov = 65;
      }

      camera.updateProjectionMatrix(); // Apply changes to the projection matrix
    };

    // Call initially to set correct values
    handleResize();

    // Add the resize listener
    window.addEventListener("resize", handleResize);

    // Clean up the listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [camera]);

  useFrame((state, delta) => {
    let time = 0;
    if (audioRef.current) {
      time = audioRef.current.currentTime * 1000;
    }
    if (timeline.current && audioRef.current) {
      if (animTarget.current.syncWithAudio) {
        timeline.current.seek(time);
      }

      const {
        dof,
        cameraY,
        rotate,
        bloomStrength,
        frontLightIntensity,
        backLightIntensity,
        ambientIntensity,
        globalIntensity,
        sOffset,
        sNoise,
        sStrobe,
        sWobble,
        fade,
      } = animTarget.current;

      state.camera.position.y = cameraY;
      state.camera.lookAt(0, 1.0, 0);

      if (flowersRef.current) {
        flowersRef.current.rotation.y = rotate;
      }
      if (frontLightRef.current) {
        frontLightRef.current.intensity =
          frontLightIntensity * Math.PI * globalIntensity;
      }
      if (backLightRef.current) {
        backLightRef.current.intensity =
          backLightIntensity * Math.PI * globalIntensity;
      }
      if (ambientLightRef.current) {
        ambientLightRef.current.intensity = ambientIntensity * globalIntensity;
      }
      if (directionalLightRef.current) {
        directionalLightRef.current.intensity = 30 * Math.PI * globalIntensity;
      }
      if (dofRef.current) {
        dofRef.current.cocMaterial.focusRange = dof;
      }

      if (samPassRef.current) {
        const sIterations = 7;
        samPassRef.current.setValues(
          sIterations,
          sOffset,
          sNoise,
          sStrobe,
          sWobble,
          time / 1000
        );
      }

      if (fadePassRef.current) {
        fadePassRef.current.setFade(fade);
      }

      if (bloomRef.current) {
        bloomRef.current.intensity = bloomStrength;
      }

      const currentTime = performance.now();
      const deltaTime = currentTime - lastTime.current;
      lastTime.current = currentTime;

      const fps = 1000 / deltaTime;
      if (currentTime > lastResetTime.current + PERFORMANCE_CHECK_WAIT) {
        onReportFPS(fps);
        lastResetTime.current = currentTime;
      }
    }
  });

  useEffect(() => {
    timeline.current = createTimeline(animTarget.current);
  }, []);

  // useEffect(() => {
  //   const gui = new GUI();
  //   if (animTarget.current) {
  //     gui.add(animTarget.current, "syncWithAudio");
  //     gui.add(animTarget.current, "rotate", 0, 2 * Math.PI, 0.01);
  //     gui.add(animTarget.current, "cameraY", 0, 8);
  //     gui.add(animTarget.current, "frontLightIntensity", 0, 500);
  //     gui.add(animTarget.current, "backLightIntensity", 0, 500);
  //     gui.add(animTarget.current, "ambientIntensity", 0, 500);
  //     gui.add(animTarget.current, "globalIntensity", 0, 500);
  //     gui.add(animTarget.current, "sWobble", 0, 1);
  //     gui.add(animTarget.current, "bloomStrength", 0, 10000);
  //   }
  //   return () => {
  //     gui.destroy();
  //   };
  // }, []);

  return (
    <>
      <perspectiveCamera position={[0, 0, 0]} />
      <ambientLight ref={ambientLightRef} intensity={0.08} />
      <spotLight
        ref={directionalLightRef}
        position={[2, 10, 2]}
        angle={0.35}
        penumbra={1}
        decay={0}
        intensity={Math.PI * 30}
        castShadow
        color={0x2f2f37}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
        shadow-camera-near={0.1}
        shadow-camera-far={20}
        shadow-camera-left={-10}
        shadow-camera-right={10}
        shadow-camera-top={10}
        shadow-camera-bottom={-10}
      />

      <pointLight
        ref={frontLightRef}
        color={0x0f0f39}
        position={[-5, 5, 0]}
        decay={0}
        distance={400}
        intensity={10 * Math.PI}
      />

      <pointLight
        ref={backLightRef}
        color={0x3c1d13}
        position={[5, 5, 0]}
        decay={0}
        distance={400}
        intensity={10 * Math.PI}
      />

      <mesh ref={groundRef} rotation={[-Math.PI / 2, 0, 0]} receiveShadow>
        <planeGeometry args={[200, 200, 1]} />
        <shadowMaterial />
      </mesh>

      <mesh position={[0, 0, -7]}>
        <planeGeometry args={[300, 300, 1]} />
        <shadowMaterial />
      </mesh>

      <group ref={flowersRef}>
        <Flowers />
      </group>

      {debugMode && <Stats showPanel={0} className="stats" />}

      <EffectComposer>
        {quality !== "lo" ? (
          <DepthOfField
            ref={dofRef}
            focusDistance={focusDistance}
            focalLength={0.2}
            bokehScale={10}
            resolutionScale={0.4}
          />
        ) : (
          <group />
        )}
        <Bloom
          ref={(e) => (bloomRef.current = e as unknown as BloomEffect)}
          intensity={10}
          luminanceThreshold={0.1}
          luminanceSmoothing={0.9}
          height={128}
          mipmapBlur={true}
          radius={0.8}
        />
        <Noise opacity={0.03} />
        <SamPass
          ref={(e) => (samPassRef.current = e as unknown as SamEffect)}
          wobble={0}
          blendFunction={BlendFunction.SET}
          iterations={0}
          offset={0}
          noise={0}
          strobe={0}
        />
        <Vignette eskil={false} offset={0.1} darkness={1.1} />
        <FadePass
          ref={(e) => (fadePassRef.current = e as unknown as FadeEffect)}
          fade={0.9}
        />
      </EffectComposer>
    </>
  );
});
