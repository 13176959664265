import { useRef } from "react";
import { Group } from "three";
import { Model } from "./Flowers2";

export const Flowers = () => {
  // const gltf = useLoader(GLTFLoader, "/mesh/flowers.glb");
  // This reference will give us direct access to the mesh
  // const meshRef = useRef<Mesh>(null);
  const groupRef = useRef<Group>(null);

  // // Traverse the GLTF scene to enable shadows
  // useEffect(() => {
  //   if (gltf.scene) {
  //     gltf.scene.traverse((node) => {
  //       if ((node as Mesh).isMesh) {
  //         console.log({ node });
  //         (node as Mesh).castShadow = true;
  //         //   (node as Mesh).receiveShadow = true; // Enable receiving shadow if needed
  //       }
  //     });
  //   }
  // }, [gltf]);

  // Set up state for the hovered and active state
  // Subscribe this component to the render-loop, rotate the mesh every frame
  // useFrame((state, delta) => {
  //   if (groupRef.current) {
  //     groupRef.current.rotation.y -= (delta * (Math.PI * 2)) / 80;
  //   }
  // });

  return (
    <group ref={groupRef} rotation={[0, Math.PI * 1.05, 0]}>
      <Model />
    </group>
  );

  // return (
  //   <mesh castShadow receiveShadow>
  //     {/* <boxGeometry args={[1, 2, 1]} /> */}

  //     <primitive ref={meshRef} object={gltf.scene} />
  //   </mesh>
  // );
};
