export const clamp = (value: number, min: number, max: number) => {
  return Math.max(min, Math.min(max, value));
};

export const clamp01 = (value: number) => clamp(value, 0, 1);

export const lerp = (v0: number, v1: number, t: number) => {
  return v0 + t * (v1 - v0);
};

export class ExponentialMovingAverage {
  private ema: number | null = null; // Current EMA value
  private alpha: number; // Smoothing factor

  constructor(smoothingFactor: number) {
    if (smoothingFactor <= 0 || smoothingFactor > 1) {
      throw new Error("Smoothing factor must be between 0 and 1 (exclusive).");
    }
    this.alpha = smoothingFactor;
  }

  update(value: number): number {
    if (this.ema === null) {
      // Initialize the EMA with the first value
      this.ema = value;
    } else {
      // Update the EMA with the new value
      this.ema = this.alpha * value + (1 - this.alpha) * this.ema;
    }
    return this.ema;
  }

  getAverage(): number | null {
    return this.ema;
  }
}
