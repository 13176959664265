/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 flowers2.glb --transform --types --shadows 
Files: flowers2.glb [7.04MB] > /Users/chris/Projects/Web/red-drapes-react/red-drapes-react/public/mesh/flowers2-transformed.glb [1MB] (86%)
*/

import * as THREE from "three";
import React from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    flowers_low_res: THREE.Mesh;
  };
  materials: {
    flowers_low_res: THREE.MeshStandardMaterial;
  };
  // animations: GLTFAction[]
};

export function Model(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/mesh/flowers2-transformed.glb"
  ) as GLTFResult;
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.flowers_low_res.geometry}
        material={materials.flowers_low_res}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.7, 0.669, 0.7]}
      />
    </group>
  );
}

useGLTF.preload("/mesh/flowers2-transformed.glb");
