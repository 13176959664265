import { wrapEffect } from "@react-three/postprocessing";
import { BlendFunction, Effect } from "postprocessing";
import { Uniform, WebGLRenderer, WebGLRenderTarget } from "three";

const fragmentShader = `
    uniform float fade;

    void mainImage(const in vec4 inputColor, const in vec2 uv, out vec4 outputColor) {
      outputColor = inputColor * (1. - fade);
    }
`;
export class FadeEffect extends Effect {
  constructor({ blendFunction = BlendFunction.SCREEN, fade = 0 } = {}) {
    super("FadeEffect", fragmentShader, {
      blendFunction,
      uniforms: new Map([["fade", new Uniform(fade)]]),
    });
  }

  setFade(newFade: number) {
    const uFade = this.uniforms.get("fade");
    if (uFade) uFade.value = newFade;
  }

  update(
    renderer: WebGLRenderer,
    inputBuffer: WebGLRenderTarget,
    deltaTime?: number
  ) {}
}

export const FadePass = wrapEffect(FadeEffect, {
  blendFunction: BlendFunction.NORMAL,
  fade: 0,
});
