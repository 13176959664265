import { Canvas } from "@react-three/fiber";
import { SceneQuality, SceneWithLoader } from "./Scene";
import { memo, useCallback, useEffect, useRef, useState } from "react";

interface QualitySettings {
  sceneQuality: SceneQuality;
  pixelRatio: number;
}

const MIN_FPS = 25;

export const MyCanvas = memo(() => {
  const [debugMode, setDebugMode] = useState(false);
  const fpsState = useRef({
    i: 0,
    prev: [60, 60, 60, 60, 60],
  });

  const [qualitySettings, setQualitySettings] = useState<QualitySettings>({
    pixelRatio: window.devicePixelRatio,
    sceneQuality: "hi",
  });

  const onReportFPS = useCallback(
    (fps: number) => {
      const state = fpsState.current;
      state.prev[state.i] = fps;
      state.i = (state.i + 1) % state.prev.length;
      const avgFPS = state.prev.reduce((a, b) => a + b, 0) / state.prev.length;

      let newPixelRatio = qualitySettings.pixelRatio;
      let newSceneQuality = qualitySettings.sceneQuality;

      if (avgFPS < MIN_FPS * 0.9) {
        if (qualitySettings.pixelRatio >= 1) {
          newPixelRatio -= 0.5;
        } else {
          newSceneQuality = "lo";
        }
      }

      if (avgFPS > MIN_FPS * 1.2) {
        if (newSceneQuality === "lo") {
          newSceneQuality = "hi";
        } else if (qualitySettings.pixelRatio < window.devicePixelRatio) {
          newPixelRatio += 0.5;
        }
      }

      if (
        newPixelRatio !== qualitySettings.pixelRatio ||
        newSceneQuality !== qualitySettings.sceneQuality
      ) {
        state.prev = state.prev.map(() => MIN_FPS * 1.1);
        setQualitySettings({
          pixelRatio: newPixelRatio,
          sceneQuality: newSceneQuality,
        });
      }
    },
    [qualitySettings]
  );

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "/") {
        setDebugMode(!debugMode);
      }
      if (debugMode) {
        if (e.key === "1") {
          setQualitySettings({ pixelRatio: 0.5, sceneQuality: "lo" });
        }
        if (e.key === "2") {
          setQualitySettings({ pixelRatio: 1, sceneQuality: "med" });
        }
        if (e.key === "3") {
          setQualitySettings({ pixelRatio: 1.5, sceneQuality: "hi" });
        }
        if (e.key === "4") {
          setQualitySettings({ pixelRatio: 2, sceneQuality: "hi" });
        }
      }
    };
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [debugMode]);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <Canvas
        shadows
        dpr={qualitySettings.pixelRatio}
        gl={{ antialias: false }}
        onCreated={(state) => {
          state.gl.setClearColor("#1a1b25");
          state.camera.position.set(0, 1.5, 3);
          state.camera.rotateX(-Math.PI * 0.06);
          state.camera.far = 10;
          state.camera.updateProjectionMatrix();
        }}
      >
        <SceneWithLoader
          key={qualitySettings.pixelRatio}
          quality={qualitySettings.sceneQuality}
          debugMode={debugMode}
          onReportFPS={onReportFPS}
        />
      </Canvas>
      {debugMode && (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            right: 0,
            background: "black ",
            color: "var(--main-color)",
          }}
        >
          {qualitySettings.sceneQuality} {qualitySettings.pixelRatio}
        </div>
      )}
    </div>
  );
});
