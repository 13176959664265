import { useEffect, useState } from "react";
import { useProgress } from "@react-three/drei";
import { Portal } from "../util/Portal";
import { lerp } from "../../helpers";
import "./Loading.css";

export const portalRoot: HTMLElement = document.getElementById(
  "loading"
) as HTMLElement;

export const Loading = () => {
  const { progress, loaded, total } = useProgress();
  const [percent, setPercent] = useState(0);
  const [loadStarted, setLoadStarted] = useState(false);

  useEffect(() => {
    setLoadStarted(true);
    const interval = setInterval(() => {
      setPercent(Math.round(lerp(percent, progress, 0.4)));
    }, 50);
    return () => clearInterval(interval);
  }, [percent, progress]);

  if (loadStarted && loaded === total) {
    return null;
  }

  return (
    <Portal container={portalRoot}>
      <div className="Loading">
        Loading
        <br />
        {percent ?? 0}%
      </div>
    </Portal>
  );
};
