import anime from "animejs";

export interface TimelineTarget {
  syncWithAudio: boolean;
  afterImage: number;
  sOffset: number;
  sNoise: number;
  sStrobe: number;
  sWobble: number;
  rotate: number;
  dof: number;
  fade: number;
  bloomStrength: number;
  cameraY: number;
  globalIntensity: number;
  ambientIntensity: number;
  frontLightIntensity: number; // blue
  backLightIntensity: number; // orange
  rays: number;
}

const smudge = {
  none: 0,
  vLight: 0.001,
  light: 0.002,
  regular: 0.003,
  large: 0.004,
};

const length = 326844;

export const createTimeline = (target: TimelineTarget) => {
  let timeline = anime.timeline({
    targets: target,
    autoplay: false,
    easing: "easeInOutQuad",
  });

  timeline
    .add(
      {
        rotate: -5 * Math.PI,
        duration: length,
        easing: "linear",
      },
      0
    )
    .add(
      {
        rotate: 0,
        duration: 0.001,
        easing: "linear",
      },
      length
    )
    // Global Lighting
    .add(
      {
        globalIntensity: 1,
        duration: 6 * 1000,
        easing: "easeOutQuad",
      },
      0
    )
    .add(
      {
        // not lost
        globalIntensity: 0.4,
        duration: 13 * 1000,
        easing: "easeOutQuad",
      },
      91 * 1000
    )
    .add(
      {
        // Low
        globalIntensity: 0.2,
        duration: 10 * 1000,
        easing: "easeOutQuad",
      },
      104 * 1000
    )
    .add(
      {
        // Higher
        globalIntensity: 3,
        duration: 4 * 1000,
        easing: "easeInQuad",
      },
      115 * 1000
    )

    .add(
      {
        // I cant take it away
        globalIntensity: 0.8,
        duration: 10 * 1000,
        easing: "easeInOutQuad",
      },
      124.534656 * 1000
    )
    .add(
      {
        // But I'd
        globalIntensity: 1,
        duration: 10 * 1000,
        easing: "easeInOutQuad",
      },
      135 * 1000
    )
    .add(
      {
        // no...
        globalIntensity: 0.5,
        duration: 5 * 1000,
        easing: "easeOutQuad",
      },
      205.3 * 1000
    )
    .add(
      {
        // something's not finished...
        globalIntensity: 1,
        duration: 10 * 1000,
        easing: "easeInQuad",
      },
      210 * 1000
    )
    .add(
      {
        globalIntensity: 0,
        duration: 10 * 1000,
        easing: "easeOutQuad",
      },
      310 * 1000
    )
    .add(
      {
        globalIntensity: 1,
        duration: 5 * 1000,
        easing: "easeOutQuad",
      },
      length
    )
    // Front light
    .add(
      {
        frontLightIntensity: 10 * Math.PI,
        duration: 10 * 1000,
        easing: "easeOutQuad",
      },
      0
    )
    .add(
      {
        frontLightIntensity: 10,
        duration: 42 * 1000,
        easing: "easeOutQuad",
      },
      262 * 1000
    )
    .add(
      {
        frontLightIntensity: 0,
        duration: 10 * 1000,
        easing: "easeOutQuad",
      },
      300 * 1000
    )
    .add(
      {
        frontLightIntensity: 10,
        duration: 5 * 1000,
        easing: "easeOutQuad",
      },
      length
    )
    // Back light

    .add(
      {
        backLightIntensity: 10 * Math.PI,
        duration: 10 * 1000,
        easing: "easeOutQuad",
      },
      0
    )
    .add(
      {
        backLightIntensity: 10,
        duration: 42 * 1000,
        easing: "easeOutQuad",
      },
      260 * 1000
    )
    .add(
      {
        backLightIntensity: 0,
        duration: 10 * 1000,
        easing: "easeOutQuad",
      },
      300 * 1000
    )
    .add(
      {
        backLightIntensity: 10,
        duration: 5 * 1000,
        easing: "easeOutQuad",
      },
      length
    )

    // DOF
    .add(
      {
        dof: 0.2,
        duration: 10 * 1000,
        easing: "easeOutQuad",
      },
      0
    )
    .add(
      {
        dof: 0.2,
        duration: 42 * 1000,
        easing: "easeOutQuad",
      },
      258 * 1000
    )
    .add(
      {
        dof: 0.1,
        duration: 8 * 1000,
        easing: "easeOutQuad",
      },
      290 * 1000
    )
    .add(
      {
        dof: 1,
        duration: 5 * 1000,
        easing: "easeOutQuad",
      },
      305 * 1000
    )
    .add(
      {
        dof: 0.1,
        duration: 5 * 1000,
        easing: "easeOutQuad",
      },
      310 * 1000
    )
    // .add(
    //   {
    //     dof: 0.2,
    //     duration: 5 * 1000,
    //     easing: "easeOutQuad",
    //   },
    //   length
    // )

    // Ambient Lighting
    .add(
      {
        // Higher
        ambientIntensity: 1,
        duration: 8 * 1000,
        easing: "easeInQuad",
      },
      115 * 1000
    )
    .add(
      {
        ambientIntensity: 0.1,
        duration: 10 * 1000,
        easing: "easeOutQuad",
      },
      125 * 1000
    )
    .add(
      {
        ambientIntensity: 5,
        duration: 10 * 1000,
        easing: "easeOutQuad",
      },
      140 * 1000
    )
    .add(
      {
        ambientIntensity: 0.3,
        duration: 10 * 1000,
        easing: "easeOutQuad",
      },
      154 * 1000
    )
    .add(
      {
        ambientIntensity: 0.7,
        duration: 2 * 1000,
        easing: "easeOutQuad",
      },
      181 * 1000
    )

    .add(
      {
        // Telepathic
        ambientIntensity: 3,
        duration: 5 * 1000,
        easing: "easeOutQuad",
      },
      190.5 * 1000
    )
    .add(
      {
        // what is that...
        ambientIntensity: 1,
        duration: 10 * 1000,
        easing: "easeOutQuad",
      },
      198 * 1000
    )
    .add(
      {
        // no...
        ambientIntensity: 1,
        duration: 1 * 1000,
        easing: "easeOutQuad",
      },
      207 * 1000
    )
    .add(
      {
        // on you
        ambientIntensity: 20,
        duration: 42 * 1000,
        easing: "easeOutQuad",
      },
      258 * 1000
    )
    .add(
      {
        ambientIntensity: 0,
        duration: 10 * 1000,
        easing: "easeOutQuad",
      },
      300 * 1000
    )
    .add(
      {
        ambientIntensity: 2,
        duration: 5 * 1000,
        easing: "easeOutQuad",
      },
      length
    )
    // Bloom
    .add(
      {
        // Higher
        bloomStrength: 1,
        duration: 1.5 * 1000,
        easing: "easeOutQuad",
      },
      116.5 * 1000
    )
    .add(
      {
        // It seems
        bloomStrength: 0.1,
        duration: 10 * 1000,
        easing: "easeInQuad",
      },
      123 * 1000
    )
    .add(
      {
        bloomStrength: 15,
        duration: 10 * 1000,
        easing: "easeOutQuad",
      },
      140 * 1000
    )
    .add(
      {
        bloomStrength: 0.1,
        duration: 10 * 1000,
        easing: "easeOutQuad",
      },
      154 * 1000
    )

    .add(
      {
        // Telepathic
        bloomStrength: 5,
        duration: 1.5 * 1000,
        easing: "easeOutQuad",
      },
      188.5 * 1000
    )
    .add(
      {
        // I know...
        bloomStrength: 0.1,
        duration: 1.5 * 1000,
        easing: "easeOutQuad",
      },
      206 * 1000
    )
    .add(
      {
        // on you
        bloomStrength: 15.5,
        duration: 40 * 1000,
        easing: "easeOutQuad",
      },
      260 * 1000
    )
    .add(
      {
        bloomStrength: 0.1,
        duration: 15 * 1000,
        easing: "easeOutQuad",
      },
      295 * 1000
    )
    // Camera
    // .add(
    //   {
    //     cameraY: 10,
    //     duration: 0,
    //     easing: "easeOutQuad"
    //   },
    //   0
    // )
    .add(
      {
        cameraY: 1.5,
        duration: 35 * 1000,
        easing: "easeOutQuad",
      },
      0
    )
    .add(
      {
        cameraY: 8,
        duration: 49 * 1000,
        easing: "easeInQuad",
      },
      270 * 1000
    )
    .add(
      {
        cameraY: 1.5,
        duration: 0.001,
        easing: "easeOutQuad",
      },
      length
    )
    // SamShader
    // .add(
    //   {
    //     // The red
    //     sOffset: smudge.regular,
    //     duration: 0.1 * 1000,
    //     easing: "easeInQuad"
    //   },
    //   25.0 * 1000
    // )
    // .add(
    //   {
    //     sOffset: smudge.none,
    //     duration: 0.1 * 1000,
    //     easing: "easeInQuad"
    //   },
    //   25.5 * 1000
    // )
    // .add(
    //   {
    //     sOffset: smudge.regular,
    //     duration: 0.1 * 1000,
    //     easing: "easeInQuad"
    //   },
    //   28 * 1000
    // )
    // .add(
    //   {
    //     sOffset: smudge.none,
    //     duration: 0.3 * 1000,
    //     easing: "easeInQuad"
    //   },
    //   28.5 * 1000
    // )
    // .add(
    //   {
    //     sOffset: smudge.light,
    //     duration: 0.1 * 1000,
    //     easing: "easeInQuad"
    //   },
    //   31 * 1000
    // )
    // .add(
    //   {
    //     sOffset: smudge.none,
    //     duration: 0.3 * 1000,
    //     easing: "easeInQuad"
    //   },
    //   31.2 * 1000
    // )
    // .add(
    //   {
    //     sOffset: smudge.large,
    //     duration: 0.1 * 1000,
    //     easing: "easeInQuad"
    //   },
    //   40.5 * 1000
    // )
    // .add(
    //   {
    //     sOffset: smudge.regular,
    //     duration: 0.1 * 1000,
    //     easing: "easeInQuad"
    //   },
    //   40.7 * 1000
    // )
    // .add(
    //   {
    //     sOffset: smudge.none,
    //     duration: 0.3 * 1000,
    //     easing: "easeInQuad"
    //   },
    //   42.3 * 1000
    // )
    // .add(
    //   {
    //     sOffset: smudge.regular,
    //     duration: 0.1 * 1000,
    //     easing: "easeInQuad"
    //   },
    //   141.0 * 1000
    // )
    // .add(
    //   {
    //     sOffset: smudge.none,
    //     duration: 0.1 * 1000,
    //     easing: "easeInQuad"
    //   },
    //   141.3 * 1000
    // )

    // .add(
    //   {
    //     sNoise: 0,
    //     duration: 0.5 * 1000,
    //     easing: "easeInQuad"
    //   },
    //   53 * 1000
    // )

    .add(
      {
        sNoise: 1,
        duration: 30 * 1000,
        easing: "easeInQuad",
      },
      239.5 * 1000
    )
    .add(
      {
        sNoise: 0,
        duration: 10 * 1000,
        easing: "easeInQuad",
      },
      270.5 * 1000
    )

    .add(
      {
        sWobble: 0.2,
        duration: 0.5 * 1000,
        easing: "easeInQuad",
      },
      184 * 1000 // Follow me
    )
    .add(
      {
        sWobble: 0,
        duration: 1 * 1000,
        easing: "easeInQuad",
      },
      185 * 1000 // Follow me end
    )
    .add(
      {
        sWobble: 0.8,
        duration: 20 * 1000,
        easing: "easeInQuad",
      },
      269.5 * 1000
    )
    .add(
      {
        sWobble: 0,
        duration: 10 * 1000,
        easing: "easeInQuad",
      },
      290.5 * 1000
    )
    // After image
    .add(
      {
        afterImage: 0,
        duration: 20 * 1000,
        easing: "easeInQuad",
      },
      249.5 * 1000
    )

    .add(
      {
        afterImage: 0.95,
        duration: 20 * 1000,
        easing: "easeInQuad",
      },
      280.5 * 1000
    )

    // .add(
    //   {
    //     afterImage: 0,
    //     duration: 3 * 1000,
    //     easing: "easeInQuad"
    //   },
    //   258.5 * 1000
    // )
    // .add(
    //   {
    //     afterImage: 1,
    //     duration: 5 * 1000,
    //     easing: "easeInQuad"
    //   },
    //   265.5 * 1000
    // )
    // .add(
    //   {
    //     afterImage: 0,
    //     duration: 10 * 1000,
    //     easing: "easeInQuad"
    //   },
    //   285.5 * 1000
    // )
    .add(
      {
        // Start
        sStrobe: 1,
        duration: 5 * 1000,
        easing: "easeOutQuad",
      },
      0
    )
    .add(
      {
        // Higher
        sStrobe: 0,
        duration: 1.5 * 1000,
        easing: "easeOutQuad",
      },
      116.5 * 1000
    )
    .add(
      {
        // Higher
        sStrobe: 1,
        duration: 5 * 1000,
        easing: "easeOutQuad",
      },
      130 * 1000
    )
    .add(
      {
        sStrobe: 0,
        duration: 30 * 1000,
        easing: "easeInQuad",
      },
      222.5 * 1000
    )

    // Fade
    .add(
      {
        fade: 0,
        duration: 35 * 1000,
        easing: "easeOutQuad",
      },
      0
    )
    .add(
      {
        fade: 1,
        duration: 10 * 1000,
        easing: "easeOutQuad",
      },
      length - 15 * 1000
    )

    // Rays
    .add(
      {
        // Higher
        rays: 0,
        duration: 3 * 1000,
        easing: "easeOutQuad",
      },
      0
    )
    .add(
      {
        // on you
        rays: 1,
        duration: 55 * 1000,
        easing: "easeOutQuad",
      },
      206 * 1000
    )
    .add(
      {
        rays: 0,
        duration: 15 * 1000,
        easing: "easeOutQuad",
      },
      295 * 1000
    );

  // After image
  // .add(
  //   {
  //     afterImage: 1,
  //     duration: 1 * 1000,
  //     easing: "easeInQuad"
  //   },
  //   24.5 * 1000
  // )
  // .add(
  //   {
  //     afterImage: 0.1,
  //     duration: 1 * 10000,
  //     easing: "easeInQuad"
  //   },
  //   28 * 1000
  // );

  [
    [4.3, smudge.vLight],
    [26.3, smudge.regular],
    [29.7, smudge.regular],
    [33.3, smudge.regular],
    [35.3, smudge.regular],
    [41.65, smudge.large],
    [55.1, smudge.regular],
    [64.8, smudge.regular],
    [78.2, smudge.regular],
    [101.0, smudge.regular],
    [141, smudge.regular],
    [181, smudge.large],
    [184, smudge.regular],
    [194, smudge.regular],
    [203, smudge.regular],
    [215, smudge.regular],
    [228.0, smudge.regular],
    [238.4, smudge.regular],
    [249.4, smudge.regular],
  ].forEach((smudge) => {
    timeline.add(
      {
        sOffset: smudge[1],
        duration: 0.1 * 1000,
        easing: "easeInQuad",
      },
      smudge[0] * 1000
    );

    timeline.add(
      {
        sOffset: 0,
        duration: 0.3 * 1000,
        easing: "easeInQuad",
      },
      (smudge[0] + 0.3) * 1000
    );
  });

  [
    [41.65, 0.6, 1],
    [52, 0.6, 0.5],
    [55, 0.6, 0.8],
    [58.5, 2, 1],
    [70, 2.6, 0.5],
    [94, 0.2, 1],
    [110, 4, 0.8, 1.0, 2.3],
    [140, 4, 1, 3, 2],
    [170, 0.2, 1],
    [190, 2, 0.4, 3, 2],
    [195, 4, 1, 3, 2],
    [202, 4, 0.5, 3, 2],
    [219, 1, 0.5, 1, 2],
    [228, 4, 0.8, 3, 2],
  ].forEach((noise) => {
    timeline.add(
      {
        sNoise: noise[2],
        duration: (noise[3] || 0.1) * 1000,
        easing: "easeInQuad",
      },
      noise[0] * 1000
    );

    timeline.add(
      {
        sNoise: 0,
        duration: (noise[4] || 0.3) * 1000,
        easing: "easeInQuad",
      },
      (noise[0] + noise[1]) * 1000
    );
  });

  return timeline;
};
